@import "../../assets/scss/variables.scss";
@import "../../assets/scss/input.scss";

$size: 18px;

.container {
    display: flex;
    margin: 0 6px;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
}

.item-gear {
    padding: 20px $wallPadding;

    input {
        width: 90px;
    }
}

.label {
    font-weight: bold;
    font-size: 1em;
    margin-left: 10px;
    color: #333;
}

.item-distance {
    padding: 0 $wallPadding;
    // background-color: green;

    .distance-container {
        display: flex;
        width: 100%;
        height: 100%;
        margin: 20px 0;

        .distance-item-1 {
            .meter {
                font-weight: bold;
                font-size: 1.1em;
            }
        }

        .distance-item-2 {
            flex: 1;
            margin-left: 16px;

            .slider {
                -webkit-appearance: none;
                width: 100%;
                height: $slider-bar-size;
                border-radius: $slider-bar-radius;
                background: $slider-background;
                outline: none;
                opacity: 0.7;
                -webkit-transition: 0.2s;
                transition: opacity 0.2s;
            }

            .slider::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: $slider-circle-size;
                height: $slider-circle-size;
                border-radius: 50%;
                background: $color-blue;
                cursor: pointer;
            }

            .slider::-moz-range-thumb {
                width: $slider-circle-size;
                height: $slider-circle-size;
                border-radius: 50%;
                background: $color-blue;
                cursor: pointer;
            }
        }
    }
}

.menuContainer {
    display: flex;
    width: 100%;
    margin-top: -12px;
    overflow: hidden;
    border-radius: $radius;

    button {
        background-color: $color-input;
        width: 100%;
        padding: 10px 0;
        border: 0;
        font-weight: bold;
        text-transform: uppercase;

        &.active {
            border-bottom: 4px solid $color-blue;
        }
    }
}
