@import '../../assets/scss/variables';

$size: 18px;

.container {
   color: $text-grey;
   padding: 0 20px;
}

.ul {
   margin-left: 0;
   padding-left: 20px;

   li {
      padding: 3px 0;
   }
}

.svg {
   width: $size;
   height: $size;
   display: inline;
}

.svg_icon {
   display: none;
}
// .bg {
//    position: absolute;
//    top: 0;
//    left: 0;
//    width: 100%;
//    height: 100%;
//    background-color: black;
//    opacity: .8;
//    z-index: 1;
// }

// .banner {
//    position: absolute;
//    color: #333;
//    bottom: 0;
//    right: 0;
//    left: 0;
//    background-color: #fff;
//    padding: 10px;
//    margin: 20px;
//    margin-bottom: 40px;
//    border-radius: 8px;
//    z-index: 2;
//    font-size: 1.1em;
//    text-align: center;
// }

.icon {
   display: inline;
   i {
      font-size: 1.2em;
   }
}

// .arrow_container {
//    position: absolute;
//    bottom: -40px;
//    left: 0;
//    right: 0;
//    text-align: center;
// }

// .arrow {
//    width: 50px;
//    height: 50px;
//  }