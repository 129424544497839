
// https://github.com/jgthms/bulma/issues/1253
.columns.is-vertical {
    flex-direction: column;
}

.expand {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}