.container {
    width: 1800px;
    margin: auto;
    background-color: green;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow: scroll;
}

.item { 
    background-color: blue;
    margin: 10px;
    width: 120px;
    height: 100px;
}