@import "../../assets/scss/variables.scss";
@import "../../assets/scss/input.scss";

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    color: black;

    .box {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    .item-count-down {
        padding: 40px 0;
        flex: 1;
        text-align: center;

        .clock {
            position: relative;
            .a {
                position: absolute;
                top: -18px;
                left: 0;
                text-transform: uppercase;
                color: #666;
                text-transform: uppercase;
                font-size: 0.9em;
            }
            .b {
                font-family: digital-mono;
                position: relative;
                font-size: 5.5em;
                padding: 0;
                margin: 0;
            }
            .c {
                position: absolute;
                bottom: -24px;
                // text-align: center;
                position: absolute;
                font-size: 0.75em;
                color: #555;
                width: 100%;
                font-weight: bold;
                display: flex;
                justify-content: space-between;

                span {
                    background-color: #efeff4;
                    padding: 2px 4px;
                    margin: 0 3px;
                    border-radius: 4px;
                    border: 1px solid #dcdcdc;

                    &:last-child {
                        margin-right: 0;
                    }

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
            .d {
                text-transform: capitalize;
            }
        }
    }

    .item-counter {
        flex: 1;
    }

    .item-laps {
        margin: 0 10px;
        margin-bottom: 16px;
        overflow: scroll;
        position: relative;

        .lap-container {
            max-height: 260px;

            @media only screen and (min-height: 890px) {
                max-height: 450px;
            }
        }
    }

    .item-btns {
        .btn-container {
            display: flex;
            height: 100%;

            .btn {
                flex: 1;
                font-size: 1.2em;
                cursor: pointer;
                display: block;
                background-color: $color-blue;
                color: white;
                border: none;
                text-transform: uppercase;
                padding: 2px;
                font-weight: bold;
                min-height: 100px;
                margin: 2px;
            }

            .btn:hover {
                background-color: $color-blue-hover;
                cursor: pointer;
            }

            .btn-stop {
                background-color: $color-red;
            }

            .btn-start {
                font-size: 35px;
            }

            .btn-save {
                background-color: #555;
            }

            .btn-save:hover {
                background-color: #555;
            }

            .btn-stop:hover {
                background-color: $color-red-hover;
            }
        }
    }
}

.timeDifference {
    position: fixed;
    display: flex;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: green;
    z-index: 2;
    text-align: center;
    color: white;
    align-items: center;
    justify-content: center;

    font-size: 10em;

    @media all and (max-height: 350px) {
        font-size: 12em;
    }

    @media all and (max-height: 600px) {
        font-size: 16em;
    }

    overflow: hidden;

    &.negative {
        background-color: $color-red;
    }
}

.fadeOut {
    opacity: 0;
    width: 0;
    height: 0;
    transition: width 1.5s 1.5s, height 1.5s 1.5s, opacity 1.5s;
}

.saveContainer {
    color: white;
    bottom: 190px;
    width: 100%;
    position: absolute;
    z-index: 1;

    .saveContent {
        text-align: center;
        background-color: black;
        margin: 10px;
        padding: 10px;
    }
}
