.container {
    height: 100%;

    h1 {
        padding: 0;
        margin: 0;
        font-family: digital;
        line-height: 30px;
        font-size: 15em;
        font-weight: normal;
    }

    .table, .row, .cell {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: none;
    }

    .table {
        display: table;
    }
    
    .row {
        display: table-row;
    }
    
    .cell {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
    }
}