@import '../../assets/scss/variables';

.container {
  width: 100%;
  text-align: center;
  border-radius: 3px;
  background-color: $color-red;
  padding: 5px 0;
  color: white;
  font-weight: bold;
  font-size: .8em;
}

.link {
  text-decoration: underline;
  color: white;
}