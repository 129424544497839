@import "./variables";

.input {
    width: 55px;
    background-color: $color-input;
    border: none;
    padding: 3px 0;
    margin-right: 3px;
    border-radius: $radius;
    font-size: 1.1em;
    text-align: center;
    font-weight: bold;
    border: 1px solid #efeff4;
    border: 2px solid #dcdcdc;
}

// .input, .textarea {
//     // padding: 20px;
//     background-color: #efeff4;
//     padding: 4px;
//     border-radius: 6px;
//     width: 90%;
// }

// .input:active, .input:focus, .is-active.input, .is-active.textarea, .is-focused.input, .is-focused.textarea, .select select.is-active, .select select.is-focused, .select select:active, .select select:focus, .textarea:active, .textarea:focus {
//     border-color: #3273dc;
//     box-shadow: 0 0 0 0.125em rgba(50,115,220,.25);
// }

// .field {
//     // border: 1px solid grey;
//     background: red;
// }

// .control {
//   display: inline;
//   background-color: blue;
//   padding: 2px;

//   &.hasIconLeft .input {
//     padding-left: 30px;
//   }
// }
