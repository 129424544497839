@import "../../assets/scss/variables";
@import "../../assets/scss/icon";

.container {
   position: fixed;
   left: 0;
   right: 0;
   bottom: 55px;
   height: 100%;
   border-bottom: none;

   .bg {
      position: absolute;
      top: 0;
      left: 0;
      background-color: black;
      opacity: 0.7;
      width: 100%;
      height: 100%;
   }

   .wrapper {
      position: absolute;
      background-color: #fff;
      left: 0;
      right: 0;
      bottom: 0;
      min-height: 100px;
      margin: 0 4px;
      padding: 20px 0;
      // border: 1px solid #dcdcdc;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      border-bottom: none;

      .childrenWrapper {
         width: 100%;
         height: 100%;
      }

      .iconWrapper {
         position: absolute;
         top: -24px;
         right: 0px;
         padding: 4px;
         background-color: white;
         border-top-right-radius: 4px;
         border-top-left-radius: 4px;
         .icon {
            color: #444;
         }
      }
   }
}
