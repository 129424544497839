@import '../../assets/scss/variables';

.container {
  padding: 0 20px;
  
  a {
    font-size: 15px;
    font-weight: bold;
  }

  .btn-download {
    display: inline-block;
    background-color: $color-red;
    border-radius: 14px;
    padding: 12px 20px;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
  }
}
