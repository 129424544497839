@import "../../assets/scss/variables";

.container {
    text-align: center;
    min-height: 200px;
    a {
        color: $color-red;
        font-weight: bold;
    }
}
