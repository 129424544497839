@import "../../assets/scss/variables";
@import "../../assets/scss/icon";

.container {
    height: 100%;
    background-color: black;
    padding: 0 2px;

    ul {
        display: flex;
        padding: 0;
        margin: 0;
        list-style-type: none;
        height: 100%;
        justify-content: space-between;

        li {
            flex: 1;
            align-items: center;
            height: 100%;
            padding: 0;
            margin: 0 2px;
            text-align: center;
            // background-color: green;

            .download {
                color: $color-red;
            }

            .active {
                .download {
                    color: black;
                }
            }

            .label {
                margin-top: 2px;
            }

            &.active {
                background-color: #fff;

                .icon,
                .label {
                    color: black;
                }
            }

            a {
                // padding: 0 4px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: white;
                text-decoration: none;
                height: 100%;
                text-align: center;
            }

            .label {
                font-size: 0.7em;
                font-weight: bold;
            }
        }
    }
}
