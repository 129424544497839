@import '../../assets/scss/variables.scss';

.row {
    flex: 1;
}

.home {
    display: flex;
    flex-direction: column;
}

.new_version {
    margin: 2px;
}

.header {
    flex: 2;
}

.main {
    flex: 13;
}

.footer {
    min-height: 55px;
}