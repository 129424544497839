@import "./assets/scss/variables.scss";

@font-face {
  font-family: "digital-mono";
  src: url(./assets/font/digital-7-mono.ttf) format("truetype");
}

@font-face {
  font-family: "digital";
  src: url(./assets/font/digital-7.ttf) format("truetype");
}

@font-face {
  font-family: "Verdana-Pro";
  src: url(./assets/font/VerdanaPro-Regular.ttf) format("truetype");
}

// @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
//   html {
//     transform: rotate(-90deg);
//     transform-origin: left top;
//     width: 100vh;
//     overflow-x: hidden;
//     position: absolute;
//     top: 100%;
//     left: 0;
//   }
// }

body {
  margin: 0;
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

input,
textarea {
  font-family: $font;
}

button:focus,
textarea:focus,
input:focus,
a:focus {
  outline: none;
}

/* https://stackoverflow.com/questions/12304012/preventing-default-context-menu-on-longpress-longclick-in-mobile-safari-ipad */
body,
button,
a {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

h2 {
  font-weight: 700;
}

// @media screen and (orientation: landscape) {
//   body {
//     transform: rotate(-90deg);
//     transform-origin: left top;
//     width: 100vh;
//     overflow-x: hidden;
//     position: absolute;
//     top: 100%;
//     left: 0;
//   }
// }
