$font: Verdana-Pro, Verdana, Helvetica, Arial, sans-serif;

$radius: 4px;

$headerHeight: 110px;
$footerHeight: 60px;
$wallPadding: 12px;

$darkGrey: #333;
$color-blue: #0071c0;
$color-blue-hover: #0265ac;
$color-red: #c00000;
$color-red-hover: rgb(158, 1, 1);
$color-input: #e2e2e2;

$text-grey: #222;

$slider-circle-size: 36px;
$slider-bar-size: 20px;
$slider-bar-radius: 6px;
$slider-background: #d3d3d3;
