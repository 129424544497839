.container {
    display: flex;
    border-bottom: 1px solid #dbdbdc;
    padding: 10px 0;
}

.positive {
    color: green;
}

.negative {
    color: red;
}

.diff {
    position: absolute;
    top: -10px;
    right: 13px;
    font-size: 0.8em;
    font-weight: bold;
}

.container.header {
    font-weight: bold;
}

.container:last-child {
    border-bottom: none;
}

.item {
    flex: 1;
    font-size: 0.8em;
}

.item-1 {
    max-width: 30px;
}

.item-split-time {
    position: relative;
}

.item-3 {
    text-align: center;
    position: relative;
}

.item-4 {
    text-align: center;
}

.item-5 {
    text-align: right;
}
