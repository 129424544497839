// @import "bulma/sass/utilities/_all.sass";
// @import "bulma/sass/grid/columns.sass";
@import "../../assets/scss/variables";

.container {
  display: flex;
  color: $darkGrey;
}

.text {
  // background-color: pink;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.item {
  // flex: 1;
}

.item-1 {
  // max-width: 200px;
  background-color: black;

  a {
    // background-color: green;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    .logo {
      width: 90px;
      padding: 0 10px;

      @media (min-width: 360px) {
        width: 130px;
      }
    }
  }
}

.item-2 {
  flex: 1;
  // background-color: blue;
  text-align: center;

  h2 {
    font-size: 1.5em;
    text-transform: uppercase;
    padding: 0;
    margin: 0;

    @media (min-width: 360px) {
      font-size: 1.7em;
    }
  }

  .version {
    bottom: -16px;
    color: #b1b1b1;
    font-size: 11px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    display: block;
  }
}

.icon {
  width: 30px;
  height: 30px;
  color: red;
}
